import Footer from '../layouts/generic/footer';
import Header from '../layouts/generic/header';
import { PolicyHeaderImage } from '../lib/images';
import { ROUTES } from '../lib/route-links';

const PrivacyPolicy = () => {   

    return (
        <div className="w-full justify-start items-center bg-white">
            <main className="w-full block">
               <Header link={ROUTES.SIGNUP} buttonText='Get Started'/>
               <section className='w-full flex min-h-[300px] bg-[#FFFFFF] bg-cover bg-no-repeat bg-[center_center]' style={{ backgroundImage: `url(${PolicyHeaderImage})` }}>
                    <div className='w-full flex items-center px-20 max-sm:px-0 max-sm:justify-center'> 
                      <h1 className='text-[#FFFFFF] font-bold text-left text-[35px] max-sm:text-[30px]'>Privacy Policy</h1>
                    </div>
                </section> 
                <section className='relative min-h-[450px] max-sm:min-h-[650px] bg-[#FFFFFF] py-10 px-20 max-sm:px-10'>
                    <div className='flex space-x-2 justify-center'>
                      <h4 className='text-[35px] font-semibold text-[#2F3934]'>ABOUT APPASTORE AND <span className='text-[#EF7822]'>THIS POLICY</span></h4>
                    </div>
                    <div className='w-full py-10 px-20 max-sm:px-5 text-left'>
                    <p className='text-[#000000] text-[18px] font-semibold mt-3'>Effective Date: (17/11/2024)
                    Last Updated: (17/11/2024)</p>
                      <h6 className='text-[20px] font-semibold text-[#2F3934] my-4'>1. Introduction</h6>
                      <p className='text-[#000000] text-[16px] mb-5'>At Appastore, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our platform, including our mobile applications, website, and related services. By using Appastore, you agree to the terms described in this policy.<br /><br />
                      We encourage you to read this Privacy Policy carefully and contact us if you have any questions.</p>
                        
                      <h6 className='text-[20px] font-semibold text-[#2F3934] my-4'>2. Scope of This Policy</h6>
                        <span className='text-[#000000] text-[16px]'>This Privacy Policy applies to all users of Appastore, including:</span>
                        <br />
                        <ul className='list-disc px-10 mb-5'>
                            <li><span className='text-[#000000] text-[16px]'><strong>Customers:</strong>  Individuals who place orders through Appastore.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Vendors:</strong>  Businesses selling products on Appastore.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Drivers:</strong> Individuals or third-party services delivering orders.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Guest Users:</strong> Individuals receiving deliveries facilitated by Appastore account holders.</span></li>
                        </ul>
                        <p className='text-[#000000] text-[16px] mb-5'>This policy adheres to local data protection regulations and industry standards.</p>
                        
                        
                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>3. Data Collection</h6>
                        <span className='text-[#000000] text-[16px]'>We collect the following types of data:</span>
                        <p className='text-[#000000] text-[16px] font-semibold mt-3'>3.1 Data You Provide to Us</p>
                        <ul className='list-none px-10 mb-4'>
                            <li><span className='text-[#000000] text-[16px]'><strong>Account Information:</strong> Name, email, phone number, and password.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Order Details:</strong> Delivery address, payment information, and preferences.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Communications:</strong> Feedback, reviews, or customer service interactions.</span></li>
                        </ul>

                        <p className='text-[#000000] text-[16px] font-semibold my-4'>3.2 Data We Collect Automatically</p>
                        <ul className='list-none px-10 mb-4'>
                            <li><span className='text-[#000000] text-[16px]'><strong>Device Information:</strong> IP address, device model, operating system, and browser type.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Usage Data:</strong> App interactions, order history, and preferences.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Location Data:</strong> Precise or approximate location (with your consent).</span></li>
                        </ul>

                        <p className='text-[#000000] text-[16px] font-semibold my-4'>3.3 Data From Third-Party Sources</p>
                        <ul className='list-none px-10 mb-5'>
                            <li><span className='text-[#000000] text-[16px]'>Vendors providing updates on product availability.</span></li>
                            <li><span className='text-[#000000] text-[16px]'>Payment processors for fraud prevention and transaction management.</span></li>
                        </ul>

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>4. How We Use Your Data</h6>
                        <span className='text-[#000000] text-[16px]'>We use your data to:</span>
                        <br />
                        <ul className='list-none px-10 mb-5'>
                            <li><span className='text-[#000000] text-[16px]'>Process orders and deliver services.</span></li>
                            <li><span className='text-[#000000] text-[16px]'>Personalize your experience on the platform.</span></li>
                            <li><span className='text-[#000000] text-[16px]'>Communicate important updates and offers.</span></li>
                            <li><span className='text-[#000000] text-[16px]'>Improve the platform's functionality and security.</span></li>
                            <li><span className='text-[#000000] text-[16px]'>We may also aggregate anonymized data for research and analysis.</span></li>
                        </ul>

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>5. Data Sharing</h6>
                        <span className='text-[#000000] text-[16px]'>We only share your data in the following situations:</span>
                        <p className='text-[#000000] text-[16px] font-semibold mt-3'>5.1 With Vendors and Drivers</p>
                        <span className='text-[#000000] text-[16px]'>To fulfill and deliver your orders.</span>
                        <p className='text-[#000000] text-[16px] font-semibold mt-3'>5.2 With Payment Processors</p>
                        <span className='text-[#000000] text-[16px]'>For secure transaction processing and fraud prevention.</span>
                        <p className='text-[#000000] text-[16px] font-semibold mt-3'>5.3 With Law Enforcement or Regulatory Authorities</p>
                        <span className='text-[#000000] text-[16px]'>To comply with legal obligations or protect the rights of Appastore and its users.</span> <br />
                        <span className='text-[#000000] text-[16px]'>We do not sell your personal data or share it with third parties for marketing purposes.</span>


                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>6. Data Security</h6>
                        <span className='text-[#000000] text-[16px]'>We implement robust security measures to protect your data, including encryption, firewalls, and access controls. While we strive to safeguard your information, no system can guarantee complete security.</span>
                        <br />

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>7. Data Retention</h6>
                        <span className='text-[#000000] text-[16px]'>We retain your data only for as long as necessary to fulfill the purposes outlined in this policy or as required by law. When no longer needed, we securely delete or anonymize your data.</span>
                        <br />

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>8. Your Rights</h6>
                        <span className='text-[#000000] text-[16px]'>You have the following rights regarding your data:</span>
                        <br />
                        <ul className='list-disc px-10 mb-5'>
                            <li><span className='text-[#000000] text-[16px]'><strong>Access and Portability:</strong> Request a copy of your data.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Correction:</strong> Update inaccurate or incomplete information.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Deletion:</strong> Request the deletion of your data, subject to legal requirements.</span></li>
                            <li><span className='text-[#000000] text-[16px]'><strong>Opt-Out:</strong> Manage preferences for marketing communications.</span></li>
                        </ul>
                        <p className='text-[#000000] text-[16px] mb-5'>To exercise your rights, contact us at support@appastore.co.</p>

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>9. Children’s Privacy</h6>
                        <span className='text-[#000000] text-[16px]'>Appastore is not intended for individuals under the age of 13. We do not knowingly collect personal data from children. If we become aware of such data, we will promptly delete it.</span>
                        <br />

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>10. Updates to This Privacy Policy</h6>
                        <span className='text-[#000000] text-[16px]'>We may update this policy periodically to reflect changes in our practices or for legal reasons. Updates will be communicated via the Appastore platform or email.</span>
                        <br />

                        <h6 className='text-[19px] font-semibold text-[#2F3934] my-4'>11. Contact Us</h6>
                        <span className='text-[#000000] text-[16px]'>For questions, concerns, or feedback, please contact:</span>
                        <br />
                        <span className='text-[#000000] text-[16px]'>Appastore Customer Support</span>
                        <br />
                        <span className='text-[#000000] text-[16px]'><strong>Email:</strong> support@appastore.co</span>
                        <br />
                        <br />
                        <p className='text-[#000000] text-[16px] my-5'>Thank you for trusting Appastore with your personal data.</p>

                    </div>
                </section>
            </main>
            <Footer type={1} />
        </div>
    );
};

export default PrivacyPolicy;