export const TwitterIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39 40"
      width={39}
      height={40}
      {...props}
    >
      <ellipse cx="19.3209" cy="20" rx="19.1358" ry="20" fill="#1E293B"/>
      <path d="M27.805 15.6901C27.2322 15.9562 26.6277 16.1224 25.9913 16.2222C26.6277 15.8231 27.1368 15.1913 27.3595 14.4264C26.755 14.7922 26.0868 15.0583 25.3549 15.2245C24.7822 14.5927 23.9549 14.1936 23.064 14.1936C21.3458 14.1936 19.9458 15.6569 19.9458 17.4527C19.9458 17.7187 19.9776 17.9515 20.0412 18.1843C17.4639 18.0513 15.1411 16.7543 13.582 14.759C13.3275 15.2578 13.1684 15.7899 13.1684 16.3885C13.1684 17.5192 13.7093 18.5168 14.5684 19.1155C14.0593 19.0822 13.582 18.9492 13.1365 18.7164V18.7496C13.1365 20.3459 14.2184 21.6761 15.6502 21.9754C15.3957 22.042 15.1093 22.0752 14.8229 22.0752C14.632 22.0752 14.4093 22.042 14.2184 22.0087C14.632 23.3057 15.7775 24.2701 17.1457 24.2701C16.0639 25.1347 14.7275 25.6668 13.2638 25.6668C13.0093 25.6668 12.7547 25.6668 12.532 25.6336C13.932 26.5647 15.5548 27.0968 17.3366 27.0968C23.0958 27.0968 26.2459 22.1085 26.2459 17.7852C26.2459 17.6522 26.2459 17.4859 26.2459 17.3529C26.8504 16.9206 27.3913 16.3552 27.805 15.6901Z" fill="white"/>
    </svg>
  );