export const ArrowRightIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 16"
      width={20}
      height={16}
      {...props}
    >
       <path d="M12 1L19 8M19 8L12 15M19 8H1" stroke="#EF7822" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );