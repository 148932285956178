export const PhoneIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 15"
      width={16}
      height={15}
      {...props}
    >
      <path d="M14.6043 10.7944L12.6505 8.84063C11.9528 8.14286 10.7666 8.422 10.4874 9.32906C10.2781 9.95708 9.58035 10.306 8.95236 10.1664C7.55683 9.8175 5.67287 8.00331 5.32398 6.538C5.11465 5.90999 5.53331 5.21222 6.1613 5.00292C7.0684 4.72381 7.3475 3.53761 6.64974 2.83985L4.696 0.886102C4.13778 0.397666 3.30047 0.397666 2.81203 0.886102L1.48628 2.21186C0.160524 3.60739 1.62583 7.30554 4.90533 10.5851C8.18482 13.8646 11.883 15.3997 13.2785 14.0041L14.6043 12.6784C15.0927 12.1201 15.0927 11.2828 14.6043 10.7944Z" fill="white"/>
    </svg>
  );