export const EmailIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      width={16}
      height={17}
      {...props}
    >
      <path d="M0.333768 3.4934C2.52971 5.35312 6.38289 8.62494 7.51539 9.64509C7.66742 9.78278 7.83049 9.85278 7.99977 9.85278C8.16871 9.85278 8.33149 9.78344 8.48317 9.64641C9.61664 8.62525 13.4698 5.35312 15.6658 3.4934C15.8025 3.37784 15.8234 3.17472 15.7127 3.03344C15.4568 2.70697 15.0753 2.51978 14.6664 2.51978H1.33311C0.924268 2.51978 0.542737 2.70697 0.286893 3.03347C0.176205 3.17472 0.197049 3.37784 0.333768 3.4934Z" fill="white"/>
      <path d="M15.8067 4.50105C15.6885 4.44605 15.5495 4.46524 15.4512 4.54924C13.0159 6.61368 9.90788 9.25986 8.93 10.141C8.38116 10.6365 7.61944 10.6365 7.06931 10.1404C6.027 9.20124 2.53675 6.23411 0.548813 4.54921C0.449844 4.46521 0.310531 4.44668 0.193344 4.50102C0.0755312 4.55574 0 4.67358 0 4.80346V13.1863C0 13.9217 0.597969 14.5196 1.33334 14.5196H14.6667C15.402 14.5196 16 13.9217 16 13.1863V4.80346C16 4.67358 15.9245 4.55543 15.8067 4.50105Z" fill="white"/>
    </svg>
  );