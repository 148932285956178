export const LocationIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 17"
      width={12}
      height={17}
      {...props}
    >
      <path d="M10.2379 3.2597C9.26683 1.58395 7.54208 0.560166 5.62411 0.521041C5.54223 0.519354 5.45983 0.519354 5.37792 0.521041C3.45998 0.560166 1.73523 1.58395 0.764169 3.2597C-0.228393 4.97258 -0.25555 7.03008 0.691513 8.76354L4.65911 16.0257C4.66089 16.0289 4.66267 16.0321 4.66451 16.0353C4.83908 16.3387 5.15179 16.5198 5.50108 16.5198C5.85033 16.5198 6.16304 16.3387 6.33757 16.0353C6.33942 16.0321 6.3412 16.0289 6.34298 16.0257L10.3106 8.76354C11.2576 7.03008 11.2304 4.97258 10.2379 3.2597ZM5.50101 7.7698C4.26036 7.7698 3.25101 6.76045 3.25101 5.5198C3.25101 4.27914 4.26036 3.2698 5.50101 3.2698C6.74167 3.2698 7.75101 4.27914 7.75101 5.5198C7.75101 6.76045 6.7417 7.7698 5.50101 7.7698Z" fill="white"/>
    </svg>
  );